<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal="`modal-edit-evol-${evolData.id}`"
      v-b-tooltip.hover
      title="Modifier"
      variant="flat-warning"
      class="btn-icon rounded-circle"
    >
      <feather-icon
        icon="SettingsIcon"
        size="20"
      />
    </b-button>
    <b-modal
      :id="`modal-edit-evol-${evolData.id}`"
      cancel-variant="outline-primary"
      ok-title="Modifier"
      cancel-title="Annuler"
      centered
      title="Nouvelle évolution"
      @ok="updateEvol(evolData)"
    >
      <b-form
        class="form-edit-evol"
        @submit.prevent
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ID"
              label-for="h-id"
              label-cols-md="4"
            >
              <b-form-input
                id="h-id"
                v-model="evolData.id"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group
              label="Date"
              label-for="h-date"
              label-cols-md="4"
            >
              <b-form-datepicker
                id="h-date"
                v-model="evolData.date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                type="date"
                placeholder="Date de création"
                hide-header
              />
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group
              label="Site"
              label-for="h-site"
              label-cols-md="4"
            >
              <v-select
                v-model="evolData.site"
                :options="siteOptions.filter(onlyUnique).sort()"
                taggable
                push-tags
              >
                <template #search="{attributes, events}">
                  <!-- eslint-disable-next-line -->
                  <input
                    class="vs__search"
                    :required="!evolData.site"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Points sensibles"
              label-for="h-sensible"
              label-cols-md="4"
            >
              <b-form-textarea
                v-model="evolData.sensiblePoints"
                name="h-sensible"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Statut"
              label-for="h-state"
              label-cols-md="4"
            >
              <b-form-select
                v-model="evolData.state"
                name="h-state"
                :options="statusOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="GitLab"
              label-for="h-gitlab"
              label-cols-md="4"
            >
              <b-form-input
                v-model="evolData.gitlab"
                name="h-gitlab"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Efficacité"
              label-for="h-efficiency"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="evolData.efficiency"
                name="h-efficiency"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Critic/amélioration"
              label-for="h-improvement"
              label-cols-md="4"
            >
              <b-form-select
                v-model="evolData.improvement"
                name="h-improvement"
                :options="criticOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Bug"
              label-for="h-bugg"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="evolData.bugg"
                name="h-bugg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Importance"
              label-for="h-importance"
              label-cols-md="4"
            >
              <b-form-select
                v-model="evolData.importance"
                name="h-importance"
                :options="importanceOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Remontées"
              label-for="h-who"
              label-cols-md="4"
            >
              <b-form-select
                v-model="evolData.who"
                name="h-who"
                :options="whoOptions"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Estimation de la résolution"
              label-for="h-resolutionEstimated"
              label-cols-md="4"
            >
              <b-form-datepicker
                id="h-resolutionEstimated"
                v-model="evolData.resolutionEstimated"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                type="date"
                placeholder="Date estimative"
                hide-header
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Résolution réelle"
              label-for="h-resolutionReal"
              label-cols-md="4"
            >
              <b-form-datepicker
                id="h-resolutionReal"
                v-model="evolData.resolutionReal"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                type="date"
                placeholder="Date réelle"
                hide-header
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="MAJ"
              label-for="h-updateNumber"
              label-cols-md="4"
            >
              <b-form-input
                v-model="evolData.updateNumber"
                name="h-updateNumber"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Indice"
              label-for="h-indexNumber"
              label-cols-md="4"
            >
              <b-form-input
                v-model="evolData.indexNumber"
                name="h-indexNumber"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Commentaire"
              label-for="h-comment"
              label-cols-md="4"
            >
              <b-form-textarea
                v-model="evolData.comment"
                name="h-comment"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Temps estimé (h)"
              label-for="h-duration"
              label-cols-md="4"
            >
              <b-form-input
                v-model="evolData.duration"
                name="h-duration"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Coût (€ sans virgule)"
              label-for="h-cost"
              label-cols-md="4"
            >
              <b-form-input
                v-model="evolData.cost"
                name="h-cost"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormGroup, BFormInput,
  BCol, BRow, BFormSelect, BFormTextarea, BFormDatepicker,
  VBTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
/* eslint no-nested-ternary: 0 */
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    BFormInput,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    evolData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      criticOptions: ['a', 'c'],
      importanceOptions: [0, 1, 2, 3],
      siteOptions: ['WebApp', 'Online', 'WebApp/Online'],
      statusOptions: ['Clos', 'En cours', 'A démarrer', 'A valider', 'A créer', 'Demande devis', 'A mettre à jour'],
      whoOptions: ['Interne', 'externe'],
    }
  },
  methods: {
    async updateEvol(evolData) {
      let improvementData = 0
      let bugData = 0
      if (evolData.improvement === 'c') {
        improvementData = 5
      } else improvementData = 3
      if (evolData.bugg) {
        bugData = 5
      } else bugData = 2
      evolData.indexNumber = improvementData * bugData * evolData.importance
      await store.dispatch('evolution/updateEvolution', { evolId: evolData.id, body: evolData })
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.form-edit-evol {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
