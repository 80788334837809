<template>
  <div>
    <evol-filters
      :state-options="stateOptions"
      :state-value.sync="stateValue"
      :gitlab-options="gitlabOptions"
      :gitlab-value.sync="gitlabValue"
      :site-options="siteOptions"
      :site-value.sync="siteValue"
    />
    <b-card
      v-if="$store.state.auth.role === 1"
      no-body
      class="pb-2"
    >
      <b-row
        class="m-2 d-flex justify-content-between"
      >
        <b-col
          cols="12"
          md="3"
        >
          <h5>Suivi des évolutions Lugsoft</h5>
        </b-col>
        <b-col
          cols="12"
          md="5"
        >
          <b-form class="w-100">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="Recherche..."
              />
            </b-input-group>
          </b-form>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mt-1 mt-md-0 d-flex align-items-center justify-content-end"
        >
          <modal-new-evol />
        </b-col>
      </b-row>
      <b-table
        v-if="evolutionList && evolutionList.length > 0"
        :items="evolutionList"
        :fields="tableColumns"
        responsive
        show-empty
        empty-text="Aucune data trouvée"
        :sticky-header="stickyHeader"
        :no-border-collapse="true"
        bordered
        striped
      >
        <template #thead-top>
          <b-tr
            class="text-center"
            :sticky-column="true"
          >
            <b-th
              colspan="3"
            >
              Détection
            </b-th>
            <b-th
              colspan="1"
              variant="secondary"
            >
              Désignation
            </b-th>
            <b-th
              variant="primary"
              colspan="3"
            >
              Mitigation
            </b-th>
            <b-th
              colspan="3"
              variant="danger"
            >
              Sévérité
            </b-th>
            <b-th
              colspan="1"
            >
              Remontées
            </b-th>
            <b-th
              colspan="2"
            >
              Résolution
            </b-th>
            <b-th
              colspan="1"
            >
              Vu
            </b-th>
            <b-th
              colspan="1"
            >
              Priorisation
            </b-th>
            <b-th
              colspan="1"
            >
              Suivi
            </b-th>
            <b-th
              colspan="3"
            >
              Invest
            </b-th>
          </b-tr>
        </template>
        <template #head()="scope">
          <div class="text-nowrap text-center">
            {{ scope.label }}
          </div>
        </template>
        <template #cell()="data">
          <div
            class="text-nowrap text-center"
          >
            {{ data.value }}
          </div>
        </template>
        <template #cell(action)="data">
          <div class="d-flex justify-content-end">
            <modal-edit-evol
              :evol-data="data.item"
            />
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover
              title="Supprimer"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteEvol(data.item)"
            >
              <feather-icon
                icon="DeleteIcon"
                size="20"
              />
            </b-button>
          </div>
        </template>
        <template #cell(createdAt)="data">
          <div>
            <small>
              {{ data.item.createdAt | displayDate }}
            </small>
          </div>
        </template>
        <template #cell(sensiblePoints)="data">
          <small
            v-b-tooltip.hover.right
            :title="data.value"
            class="text-nowrap"
          >
            {{ data.value | truncate(30) }}
          </small>
        </template>
        <template #cell(state)="data">
          <b-badge
            pill
            :variant="`light-${resolveStateVariant(data.item.state)}`"
            class="text-capitalize d-flex justify-content-center"
          >
            {{ data.item.state }}
          </b-badge>
        </template>
        <template #cell(efficiency)="data">
          <b-avatar
            :variant="`light-${resolveBoolVariant(data.item.efficiency)}`"
          >
            <feather-icon
              :icon="data.item.efficiency ? 'CheckIcon' : 'XIcon'"
            />
          </b-avatar>
        </template>
        <template #cell(bugg)="data">
          <b-avatar
            :variant="`light-${resolveBoolVariant(data.item.bugg)}`"
          >
            <feather-icon
              :icon="data.item.bugg === true ? 'AlertTriangleIcon' : 'XIcon'"
            />
          </b-avatar>
        </template>
        <template #cell(comment)="data">
          <small
            v-b-tooltip.hover.right
            :title="data.value"
            class="text-nowrap"
          >
            {{ data.value | truncate(30) }}
          </small>
        </template>
        <template #cell(resolutionEstimated)="data">
          <div>
            <small v-if="data.value">
              {{ data.value | displayDate }}
            </small>
          </div>
        </template>
        <template #cell(resolutionReal)="data">
          <div>
            <small v-if="data.value">
              {{ data.value | displayDate }}
            </small>
          </div>
        </template>
      </b-table>
      <div
        v-else
        class="text-center"
      >
        <b-spinner
          v-if="isLoading"
          class="mt-5 mb-5"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTable, BButton, BTr, BTh, BSpinner,
  BRow, VBTooltip, BAvatar, BBadge,
  BFormInput, BForm, BInputGroup, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ModalNewEvol from './ModalNewEvol.vue'
import EvolFilters from './EvolFilters.vue'
import ModalEditEvol from './ModalEditEvol.vue'

export default {
  components: {
    BCol,
    BCard,
    BTable,
    BRow,
    BButton,
    ModalNewEvol,
    ModalEditEvol,
    BTr,
    BTh,
    BAvatar,
    BBadge,
    BSpinner,
    EvolFilters,
    BFormInput,
    BForm,
    BInputGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      evolutionList: [],
      evolutionListTotal: [],
      tableColumns: [
        {
          key: 'id', label: 'ID', sortable: true, stickyColumn: true, isRowHeader: true, variant: 'primary',
        },
        {
          key: 'createdAt', label: 'Date', sortable: true,
        },
        {
          key: 'site', label: 'Site', sortable: false,
        },
        {
          key: 'sensiblePoints', label: 'Points sensibles', sortable: false,
        },
        {
          key: 'state', label: 'Statut', sortable: false,
        },
        {
          key: 'gitlab', label: 'Gitlab', sortable: false, variant: 'info',
        },
        {
          key: 'efficiency', label: 'Efficacité', sortable: false, tdClass: 'text-center',
        },
        {
          key: 'improvement', label: 'Critic/Amélioration', sortable: false,
        },
        {
          key: 'bugg', label: 'Bug', sortable: false,
        },
        {
          key: 'importance', label: 'Importance (3->0)', sortable: false,
        },
        {
          key: 'who', label: 'Qui?', sortable: false,
        },
        {
          key: 'resolutionEstimated', label: 'Date estimée', sortable: false,
        },
        {
          key: 'resolutionReal', label: 'Date réelle', sortable: false,
        },
        {
          key: 'updateNumber', label: 'MAJ', sortable: false,
        },
        {
          key: 'indexNumber', label: 'Indice', sortable: false, variant: 'primary',
        },
        {
          key: 'comment', label: 'Commentaires', sortable: false,
        },
        {
          key: 'duration', label: 'Temps estimé', sortable: false,
        },
        {
          key: 'cost', label: 'Coût (€)', sortable: false, variant: 'info', tdClass: 'font-weight-bold',
        },
        {
          key: 'action', label: 'Actions',
        },
      ],
      stickyHeader: true,
      isLoading: false,
      // filters
      stateValue: null,
      stateOptions: ['Clos', 'En cours', 'A démarrer', 'A valider', 'A créer', 'Demande devis', 'A mettre à jour'],
      gitlabValue: null,
      gitlabOptions: [],
      siteValue: null,
      siteOptions: [],
      // searchbar
      searchQuery: null,
    }
  },
  watch: {
    stateValue() {
      this.filterEvol()
    },
    gitlabValue() {
      this.filterEvol()
    },
    siteValue() {
      this.filterEvol()
    },
    searchQuery(newData) {
      this.searchQuery = newData.toLowerCase()
      this.searchDevice()
    },
  },
  mounted() {
    this.getEvolutions()
  },
  methods: {
    getEvolutions() {
      this.isLoading = true
      store.dispatch('evolution/findAllEvolutions')
        .then(response => {
          this.evolutionList = response
          this.evolutionListTotal = JSON.parse(JSON.stringify(response))
          this.isLoading = false
          const gitlabArray = []
          const siteArray = []
          this.$store.state.evolution.evolutionsList.forEach(elem => {
            if (elem.gitlab) {
              gitlabArray.push(elem.gitlab)
            }
            if (elem.site) {
              siteArray.push(elem.site)
            }
          })
          this.gitlabOptions = [...new Set(gitlabArray)]
          this.siteOptions = [...new Set(siteArray)]
        })
        .catch(err => {
          console.log('err', err)
          this.evolutionList = []
          this.evolutionListTotal = []
          this.isLoading = false
        })
    },
    addData(data) {
      this.evolutionList.push(data)
    },
    deleteEvol(evolData) {
      this.$swal({
        title: 'Êtes-vous sûr de vouloir supprimer ?',
        text: 'Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('evolution/deleteEvolution', evolData.id)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Supprimé!',
                text: 'L\'évolution a bien été supprimé',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          this.$router.go()
        }
      })
    },
    resolveStateVariant(data) {
      if (data === 'En cours') return 'info'
      if (data === 'Clos') return 'success'
      if (data === 'A démarrer') return 'danger'
      if (data === 'A créer') return 'dark'
      return 'warning'
    },
    resolveBoolVariant(data) {
      if (data === true) return 'success'
      return 'dark'
    },
    filterEvol() {
      this.evolutionList = this.evolutionListTotal
      if (this.stateValue) {
        this.evolutionList = this.evolutionList.filter(evol => evol.state === this.stateValue)
      }
      if (this.gitlabValue) {
        this.evolutionList = this.evolutionList.filter(evol => evol.gitlab === this.gitlabValue)
      }
      if (this.siteValue) {
        this.evolutionList = this.evolutionList.filter(evol => evol.site === this.siteValue)
      }
    },
    searchDevice() {
      this.evolutionList = this.evolutionListTotal.filter(evol => {
        if (evol.sensiblePoints) {
          return evol.sensiblePoints.toLowerCase().includes(this.searchQuery)
        }
        return false
      })
    },
  },
}
</script>

<style scoped>
.b-table-sticky-header {
  max-height: 70vh;
}
</style>
