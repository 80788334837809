<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filtres
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0"
        >
          <label>Site</label>
          <v-select
            :value="siteValue"
            :options="siteOptions"
            class="w-100"
            @input="(val) => changeFilter(val, 'SITE')"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0"
        >
          <label>Statut</label>
          <v-select
            :value="stateValue"
            :options="stateOptions"
            class="w-100"
            @input="(val) => changeFilter(val, 'STATE')"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0"
        >
          <label>Gitlab</label>
          <v-select
            :value="gitlabValue"
            :options="gitlabOptions"
            class="w-100"
            @input="(val) => changeFilter(val, 'GITLAB')"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex justify-content-end align-items-end"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline"
            class="btn-icon"
            @click="resetFilters"
          >
            Réinitialiser
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    stateOptions: {
      type: Array,
      required: true,
    },
    stateValue: {
      type: String,
      default: null,
    },
    gitlabOptions: {
      type: Array,
      required: true,
    },
    gitlabValue: {
      type: String,
      default: null,
    },
    siteOptions: {
      type: Array,
      required: true,
    },
    siteValue: {
      type: String,
      default: null,
    },
  },
  methods: {
    resetFilters() {
      this.$emit('update:stateValue', null)
      this.$emit('update:gitlabValue', null)
      this.$emit('update:siteValue', null)
    },
    changeFilter(value, filter) {
      if (filter === 'STATE') {
        this.$emit('update:stateValue', value)
      }
      if (filter === 'GITLAB') {
        this.$emit('update:gitlabValue', value)
      }
      if (filter === 'SITE') {
        this.$emit('update:siteValue', value)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
